import { listener, ref } from '@/components/utils/dom';
import { logger } from '@/components/utils/logger';
import type {
	SlIcon,
	ToastColor,
	ToastIcon,
	ToastOptions,
	ToastPosition,
	ToastType,
	TouchPoint,
} from '@/plugins/@toaster/types';
import { uuid } from '@/plugins/@toaster/utils';

const VIEWPORT_OFFSET: string = '16px';
const VISIBLE_TOASTS_AMOUNT: number = 4;
const GAP: number = 8;
const TOAST_LIFETIME: number = 3000;
const TIME_BEFORE_UNMOUNT: number = 200;
const TOAST_WIDTH: number = 356;
const DEFAULT_TYPE: ToastType = 'default';
const DEFAULT_POSITION: ToastPosition = 'top-right';

export const toast = (
	title: string,
	{ description, type, position }: ToastOptions = {
		description: '',
		type: DEFAULT_TYPE,
		position: DEFAULT_POSITION,
	},
): void => {
	const wrapper: HTMLDivElement | null = ref("[data-toaster='wrapper']");
	if (!wrapper) {
		logger.error(`No wrapper element found, please add "data-toaster='wrapper'" after the body tag.`);

		return;
	}

	const list: HTMLOListElement | null = ref("[data-toaster='list']");
	if (!list) {
		renderToaster(position);
		registerMouseOver();
	}

	updatePosition(position);

	show(title, { description, type, position });
};

const renderToaster = (position: ToastPosition): void => {
	const toasterWrapper: HTMLDivElement | null = ref("[data-toaster='wrapper']");
	if (!toasterWrapper) {
		return;
	}

	const ol: HTMLOListElement = document.createElement('ol');
	toasterWrapper.append(ol);

	const [y, x] = toasterWrapper.getAttribute('data-position')?.split('-') ?? position.split('-');

	ol.outerHTML = `<ol
        data-toaster='list'
        data-x-position='${x}'
        data-y-position='${y}'
        style='
            --front-toast-height: 0px;
            --offset: ${VIEWPORT_OFFSET};
            --width: ${TOAST_WIDTH}px;
            --gap: ${GAP}px'
        >
        </ol>`;
};

const updatePosition = (position: ToastPosition): void => {
	const list: HTMLOListElement | null = ref("[data-toaster='list']");
	if (!list) {
		return;
	}

	const [y, x] = list.parentElement?.getAttribute('data-position')?.split('-') ?? position.split('-');
	if (x !== list.getAttribute('data-x-position') || y !== list.getAttribute('data-y-position')) {
		list.setAttribute('data-x-position', x);
		list.setAttribute('data-y-position', y);
		for (let i = 0; i < list.children.length; i += 1) {
			const el = list.children.item(i) as HTMLLIElement;
			el.setAttribute('data-x-position', x);
			el.setAttribute('data-y-position', y);
		}
	}
};

const registerMouseOver = (): void => {
	const list: HTMLOListElement | null = ref("[data-toaster='list']");
	if (!list) {
		return;
	}

	const expandToasts = (): void =>
		Array.from(list.children).forEach((el: Element) => {
			const li = el as HTMLLIElement;

			if (li.getAttribute('data-expanded') === 'true') {
				return;
			}

			li.setAttribute('data-expanded', 'true');

			clearRemoveTimeout(li);
		});

	const collapseToasts = (): void =>
		Array.from(list.children).forEach((el: Element) => {
			const li = el as HTMLLIElement;

			if (li.getAttribute('data-expanded') === 'false') {
				return;
			}

			li.setAttribute('data-expanded', 'false');

			registerRemoveTimeout(li);
		});

	// expand all toasts on mouse enter
	listener(list, 'mouseenter', expandToasts);
	// collapse all toasts on mouse leave
	listener(list, 'mouseleave', collapseToasts);
};

const show = (title: string, { description, type, position }: ToastOptions): void => {
	const list: HTMLOListElement | null = ref("[data-toaster='list']");
	if (!list) {
		return;
	}

	renderToast(list, title, { description, type, position });

	window.setTimeout(() => {
		const el = list.children[0] as HTMLLIElement;
		const height = el.getBoundingClientRect().height;

		el.setAttribute('data-mounted', 'true');
		el.setAttribute('data-initial-height', `${height}`);
		el.style.setProperty('--initial-height', `${height}px`);
		list.style.setProperty('--front-toast-height', `${height}px`);

		refreshProperties();
		registerRemoveTimeout(el);
		closeToast(el);
		enableSwipeToDismiss(el);
	}, 16);
};

const renderToast = (
	list: HTMLElement,
	title: string,
	{ description, type, position }: ToastOptions,
): { toast: HTMLLIElement; id: string } => {
	const toast: HTMLLIElement = document.createElement('li');
	list.prepend(toast);

	const id: string = uuid();
	const { length: count } = list.children;

	// generate toast icons
	const iconMap: ToastIcon = {
		success: 'check',
		warning: 'warning',
		danger: 'error',
		info: 'info',
		default: 'notification-bell',
	};
	const iconType: string = iconMap[type] || iconMap.default;

	// generate toast title color
	const colorMap: ToastColor = {
		success: 'success',
		warning: 'warning',
		danger: 'danger',
		info: 'info',
		default: 'primary',
	};
	const color: string = colorMap[type] || colorMap.default;

	toast.outerHTML = `<li class='toast px-3 py-4 flex flex-col items-start border border-dashed border-slate-400 dark:border-slate-600 bg-slate-50 dark:bg-darker rounded-md transition-all duration-300 ease-out' data-id='${id}' data-removed='false' data-mounted='false' data-front='true' data-expanded='false' data-index='${0}' data-y-position='${
		list.getAttribute('data-y-position') ?? position.split('-')[0]
	}' data-x-position='${
		list.getAttribute('data-x-position') ?? position.split('-')[1]
	}' style='--index: 0; --toasts-before: ${0}; --z-index: ${count}; --offset: 0px; --initial-height: 0px;'>
                            <div class='w-full flex items-center gap-x-3'>
                                <div class='flex items-center justify-center'>
                                    <lord-icon class='size-6 flex-shrink-0 current-color' trigger='loop' src='/assets/icons/lordicons/${iconType}.json'></lord-icon>
								</div>
								<div class='flex flex-col'>
                                    <h3 class='text-sm !text-${color}-600 dark:!text-${color}-200' role='heading'>
                                        ${title}
                                    </h3>
                                    ${
																			description == null
																				? ''
																				: `
                                        <div class='text-xs text-black dark:text-white font-medium font-montserrat'>
	                                        ${description}
	                                    </div>
	                                `
																		}
                                </div>
                                <sl-icon data-toaster='toast-close-button' class='!text-gray-400 size-5 ms-auto' library='heroicons' name='x-mark'></sl-icon>
                            </div>
                       </li>`;

	return { toast, id };
};

const refreshProperties = (): void => {
	const list: HTMLOListElement | null = ref("[data-toaster='list']");
	if (!list) {
		return;
	}

	let heightsBefore = 0;
	let removed = 0;

	for (let i = 0; i < list.children.length; i += 1) {
		const el = list.children[i] as HTMLLIElement;

		if (el.getAttribute('data-removed') === 'true') {
			removed += 1;
			continue;
		}

		const idx = i - removed;

		el.setAttribute('data-index', `${idx}`);
		el.setAttribute('data-front', idx === 0 ? 'true' : 'false');
		el.setAttribute('data-visible', idx < VISIBLE_TOASTS_AMOUNT ? 'true' : 'false');

		el.style.setProperty('--index', `${idx}`);
		el.style.setProperty('--toasts-before', `${idx}`);
		el.style.setProperty('--offset', `${GAP * idx + heightsBefore}px`);
		el.style.setProperty('--z-index', `${list.children.length - i}`);

		heightsBefore += Number(el.getAttribute('data-initial-height'));
	}
};

const registerRemoveTimeout = (el: HTMLLIElement): void => {
	const tid = window.setTimeout(() => {
		remove(el);
	}, TOAST_LIFETIME);
	el.setAttribute('data-remove-tid', `${tid}`);
};

const clearRemoveTimeout = (el: HTMLLIElement): void => {
	const tid = el.getAttribute('data-remove-tid');

	if (tid != null) {
		window.clearTimeout(+tid);
	}
};

const remove = (el: HTMLLIElement): void => {
	el.setAttribute('data-removed', 'true');
	refreshProperties();

	const tid = window.setTimeout(() => {
		el.parentElement?.removeChild(el);
	}, TIME_BEFORE_UNMOUNT);
	el.setAttribute('data-unmount-tid', `${tid}`);
};

const closeToast = (el: HTMLLIElement): void => {
	const button: SlIcon | null = ref("[data-toaster='toast-close-button']", el);
	if (!button) {
		return;
	}

	listener(button, 'click', () => remove(el));
};

const enableSwipeToDismiss = (toast: HTMLLIElement) => {
	let startTouch: TouchPoint | undefined;
	let isDragging = false;
	let swipeDirection: 'x' | 'y' | undefined;

	// Apply the move effect to the toast based on determined swipe direction
	const moveToast = (distanceX: number, distanceY: number) => {
		requestAnimationFrame(() => {
			// Move strictly along the X-axis or Y-axis
			if (swipeDirection === 'x') {
				toast.style.transform = `translateX(${distanceX}px)`;
			} else if (swipeDirection === 'y') {
				toast.style.transform = `translateY(${distanceY}px)`;
			}

			// Adjust opacity based on the distance for visual feedback
			const distance = swipeDirection === 'x' ? distanceX : distanceY;
			toast.style.opacity = `${1 - Math.abs(distance) / 100}`;
		});
	};

	// Reset the toast position and opacity
	const resetToastPosition = () => {
		requestAnimationFrame(() => {
			toast.style.transform = '';
			toast.style.opacity = '';
		});
	};

	// Determine swipe direction based on initial movement
	const determineSwipeDirection = (distanceX: number, distanceY: number) => {
		// Determine direction if not already set
		if (!swipeDirection) {
			swipeDirection = Math.abs(distanceX) > Math.abs(distanceY) ? 'x' : 'y';
		}
	};

	// Handle the start of a swipe or drag
	const onStart = (point: TouchPoint) => {
		startTouch = point;
		isDragging = true;
		swipeDirection = undefined; // Reset direction at the start of each new interaction
	};

	// Handle the end of a swipe or drag
	const onEnd = (point: TouchPoint) => {
		if (!startTouch || !isDragging) {
			return;
		}

		const distanceX = point.x - startTouch.x;
		const distanceY = point.y - startTouch.y;
		const swipeThreshold = 50; // Threshold for dismissal

		if (swipeDirection === 'x' && Math.abs(distanceX) > swipeThreshold) {
			setTimeout(() => remove(toast), 300); // Use your removal logic
		} else if (swipeDirection === 'y' && Math.abs(distanceY) > swipeThreshold) {
			setTimeout(() => remove(toast), 300); // Use your removal logic
		} else {
			resetToastPosition();
		}

		startTouch = undefined;
		isDragging = false;
	};

	// Handle touch and mouse events
	// Setup similar to previous, with the addition of determineSwipeDirection() call in touchmove and mousemove handlers
	toast.addEventListener('touchstart', (event) => {
		const touch = event.touches[0];
		onStart({ x: touch.screenX, y: touch.screenY });
	});

	toast.addEventListener('touchmove', (event) => {
		if (!startTouch || !isDragging) {
			return;
		}

		const touch = event.touches[0];
		const currentTouch = { x: touch.screenX, y: touch.screenY };
		determineSwipeDirection(currentTouch.x - startTouch.x, currentTouch.y - startTouch.y);
		moveToast(currentTouch.x - startTouch.x, currentTouch.y - startTouch.y);
		event.preventDefault();
	});

	toast.addEventListener('touchend', (event) => {
		const touch = event.changedTouches[0];
		onEnd({ x: touch.screenX, y: touch.screenY });
	});

	toast.addEventListener('mousedown', (event) => {
		onStart({ x: event.screenX, y: event.screenY });
	});

	toast.addEventListener('mousemove', (event) => {
		if (!startTouch || !isDragging) {
			return;
		}

		const currentTouch = { x: event.screenX, y: event.screenY };
		const distanceX = currentTouch.x - startTouch.x;
		const distanceY = currentTouch.y - startTouch.y;

		moveToast(distanceX, distanceY);
		event.preventDefault(); // Prevent text selection
	});

	toast.addEventListener('mouseup', (event) => {
		onEnd({ x: event.screenX, y: event.screenY });
		resetToastPosition();
	});

	// Cleanup function to remove event listeners
	return () => {
		toast.removeEventListener('touchstart', onStart as any);
		toast.removeEventListener('touchmove', onStart as any);
		toast.removeEventListener('touchend', onEnd as any);
		toast.removeEventListener('mousedown', onStart as any);
		toast.removeEventListener('mousemove', onStart as any);
		toast.removeEventListener('mouseup', onEnd as any);
	};
};
