import { isValidIMEI } from '@/components/utils/imei';
import type { Dayjs } from '@/plugins/@dayjs';
import dayjs from '@/plugins/@dayjs';
import type { FieldsInterface } from 'just-validate';
import { Rules } from 'just-validate';

const required = [
	{
		rule: Rules.Required,
	},
];

const email = [
	{
		rule: Rules.Required,
	},
	{
		rule: Rules.Email,
	},
];

const code = [
	{
		rule: Rules.Required,
	},
	{
		rule: Rules.Number,
	},
	{
		rule: Rules.CustomRegexp,
		value: /^\d{1,6}$/,
	},
];

const singleNumber = [
	{
		rule: Rules.Required,
	},
	{
		rule: Rules.Number,
	},
	{
		rule: Rules.CustomRegexp,
		value: /^\d$/,
	},
];

const strongPassword = [
	{
		rule: Rules.Required,
	},
	{
		rule: Rules.StrongPassword,
	},
];

const phoneNumber = [
	{
		rule: Rules.Required,
	},
	{
		validator: (value: string | boolean): boolean => {
			const phoneRegex: RegExp = /^0\d{9}$/;

			return typeof value === 'string' && phoneRegex.test(value);
		},
		errorMessage: 'Veuillez saisir un numéro de téléphone valide.',
	},
];

const postalCode = [
	{
		rule: Rules.Required,
	},
	{
		rule: Rules.MinLength,
		value: 5,
		errorMessage: 'Veuillez saisir un code postal valide.',
	},
	{
		rule: Rules.MaxLength,
		value: 5,
		errorMessage: 'Veuillez saisir un code postal valide.',
	},
	{
		validator: (value: string | boolean): boolean => {
			const codePostalRegex: RegExp = /^\d{5}$/;

			return typeof value === 'string' && codePostalRegex.test(value);
		},
		errorMessage: 'Veuillez saisir un code postal valide.',
	},
];

const phoneIMEI = [
	{
		rule: Rules.Required,
	},
	{
		rule: Rules.MinLength,
		value: 15,
		errorMessage: 'Veuillez saisir un IMEI valide.',
	},
	{
		rule: Rules.MaxLength,
		value: 15,
		errorMessage: 'Veuillez saisir un IMEI valide.',
	},
	{
		validator: (value: string | boolean): boolean => {
			const imeiRegex: RegExp = /^\d{15}$/;

			if (typeof value === 'string' && imeiRegex.test(value)) {
				return isValidIMEI(value);
			}

			return false;
		},
		errorMessage: 'Veuillez saisir un IMEI valide.',
	},
];

const tooOldDate = [
	{
		rule: Rules.Required,
	},
	{
		validator: (value: string | boolean): boolean => {
			if (typeof value !== 'string') {
				return false;
			}

			const inputDate: Dayjs = dayjs(value, 'DD/MM/YYYY');
			const today: Dayjs = dayjs();
			const twoYearsAgo: Dayjs = dayjs().subtract(2, 'year');

			return (
				today.isSame(inputDate, 'day') ||
				(!today.isBefore(inputDate, 'day') && inputDate.isBetween(twoYearsAgo, today, 'day', '[]'))
			);
		},
		errorMessage: 'La date saisie ne doit pas être dans le futur, ou trop ancienne (+2ans).',
	},
];

// functions
const confirmPassword = (password: string) => [
	{
		rule: Rules.Required,
	},
	{
		validator: (value: string | boolean, fields: FieldsInterface): boolean => {
			if (fields[password] && fields[password].elem) {
				return value === fields[password].elem.value;
			}

			return true;
		},
		errorMessage: 'Les mots de passe ne correspondent pas !',
	},
];

export {
	required,
	email,
	code,
	singleNumber,
	strongPassword,
	phoneNumber,
	postalCode,
	phoneIMEI,
	tooOldDate,
	confirmPassword,
};
