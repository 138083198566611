import {
	code,
	confirmPassword,
	email,
	phoneIMEI,
	phoneNumber,
	postalCode,
	required,
	singleNumber,
	strongPassword,
	tooOldDate,
} from '@/components/plugins/@just-validate/rules';
import JustValidate, { Rules } from 'just-validate';
import type { GlobalConfigInterface } from 'just-validate';

const LOCALE: string = 'fr';

enum Locales {
	required = 'Ce champ est obligatoire.',
	email = 'Veuillez saisir une adresse e-mail valide.',
	minLength = 'Le mot de passe doit contenir au moins :value caractères.',
	maxLength = 'Le mot de passe doit contenir maximum :value caractères.',
	password = 'Le mot de passe doit contenir au moins huit caractères, au moins une lettre et un chiffre.',
	number = 'Veuillez saisir le code.',
	integer = 'Veuillez saisir un nombre entier.',
	maxNumber = 'Le code doit contenir maximum :value chiffres.',
	minNumber = 'Le code doit contenir minimum :value chiffres.',
	strongPassword = 'Le mot de passe doit contenir au moins huit caractères, au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial.',
	customRegexp = 'Veuillez saisir le code qui est de 6 chiffres.',
	minFilesCount = 'Veuillez ajouter au moins :value fichier.',
	maxFilesCount = 'Veuillez ajouter maximum :value fichier.',
	files = 'Veuillez ajouter un fichier valide.',
}

const createLocales = (key: Rules, message: string) => ({
	key,
	dict: {
		[LOCALE]: message,
	},
});

const addLocales = Object.values(Rules).map((rule) => createLocales(rule, Locales[rule]));

const initConfig = {
	errorFieldCssClass: ['data-user-invalid'],
	errorLabelCssClass: ['data-user-label-invalid'],
	successFieldCssClass: ['data-user-valid'],
	successLabelCssClass: ['data-user-label-valid'],
	focusInvalidField: true,
	lockForm: true,
	testingMode: false,
	validateBeforeSubmitting: true,
	submitFormAutomatically: false,
} as GlobalConfigInterface;

export const justValidate = (getSelector: string): JustValidate => {
	const justValidateInstance: JustValidate = new JustValidate(getSelector, initConfig, addLocales);

	justValidateInstance.setCurrentLocale(LOCALE);

	return justValidateInstance;
};

export type { JustValidate };
export {
	required,
	email,
	code,
	singleNumber,
	strongPassword,
	phoneNumber,
	postalCode,
	phoneIMEI,
	tooOldDate,
	confirmPassword,
};
