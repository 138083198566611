import Instance from '@/components/api/instance';
import { toast } from '@/plugins/@toaster';
import { AxiosError, isAxiosError } from 'axios';

type TErrorWithMessageAndStatus = {
	message: string;
	status: number;
};

const isErrorWithMessageAndStatus = (
	error: unknown,
): error is TErrorWithMessageAndStatus =>
	typeof error === 'object' &&
	error !== null &&
	'message' in error &&
	'statusCode' in error &&
	typeof (error as Record<string, unknown>).message === 'string' &&
	typeof (error as Record<string, unknown>).statusCode === 'number';

const toErrorWithMessageAndStatus = (
	maybeError: unknown,
): TErrorWithMessageAndStatus => {
	if (isErrorWithMessageAndStatus(maybeError)) {
		return maybeError;
	}

	let message: string;
	let status: number;

	try {
		const errorObj: TErrorWithMessageAndStatus = JSON.parse(
			JSON.stringify(maybeError),
		) as {
			message: string;
			status: number;
		};

		message = errorObj.message || String(maybeError);
		status = errorObj.status || 500;
	} catch {
		message = String(maybeError);
		status = 500;
	}

	return { message, status };
};

const handleAxiosError = async (error: any): Promise<void> => {
	// get environment variable
	const instance: Instance = new Instance('/api');

	const { data } = await instance.Get('/get/env');

	if (data.env === 'development') {
		if (error instanceof AxiosError) {
			console.log('Error response status: ', error.response?.status);

			if (isAxiosError(error)) {
				console.log('Error message: ', error.message);
			}
		} else {
			console.log(toErrorWithMessageAndStatus(error.response.data));
		}
	} else {
		toast('Une erreur est survenue !', {
			description: 'Veuillez réessayer plus tard.',
			type: 'danger',
			position: 'top-right',
		});
	}
};

const getErrorMessage = (error: unknown): string =>
	toErrorWithMessageAndStatus(error).message;
const getStatusCode = (error: unknown): number =>
	toErrorWithMessageAndStatus(error).status;
const getErrorTemplate = (error: unknown): string | undefined => {
	if (error instanceof AxiosError && error.response?.data?.template) {
		return error.response.data.template;
	}

	return undefined;
};

export { getErrorMessage, getStatusCode, getErrorTemplate, handleAxiosError };

// HMR (Hot Module Replacement)
if (module.hot) {
	module.hot.accept();
	module.hot.dispose((): void => {
		console.clear();
	});
}
