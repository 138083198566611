import type { SlButton, SlIconButton } from '@shoelace-style/shoelace';

type Selector = SlButton | SlIconButton | HTMLButtonElement | HTMLElement;

export const setLoadingState = (
	getSelector: Selector | null,
	isLoading: boolean,
) => {
	if (!getSelector) {
		return;
	}

	isLoading
		? getSelector.setAttribute('loading', 'true')
		: getSelector.removeAttribute('loading');
};
