import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import 'dayjs/locale/fr.js';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

dayjs.locale('fr');
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

export type { Dayjs };
export default dayjs;

// HMR (Hot Module Replacement)
if (module.hot) {
	module.hot.accept();
	module.hot.dispose((): void => {
		console.clear();
	});
}
