export const isValidIMEI = (imei: string): boolean => {
	const imeiFormatted: string = imei.trim();

	if (imeiFormatted.length !== 15) {
		return false;
	}

	if (Number.isNaN(imeiFormatted)) {
		return false;
	}

	const sum: number = imeiFormatted
		.split('')
		.map((digit: string, index: number) => {
			let num: number = Number.parseInt(digit, 10);

			if (index % 2 !== 0) {
				num *= 2;

				if (num > 9) {
					num -= 9;
				}
			}

			return num;
		})
		.reduce((acc, num) => acc + num, 0);

	return sum % 10 === 0;
};
