import axios from 'axios';
import type {
	AxiosInstance,
	AxiosRequestConfig,
	AxiosResponse,
	InternalAxiosRequestConfig,
} from 'axios';

type LoaderConfig = {
	loader: boolean;
	title?: string;
	description?: string;
	container?: HTMLDivElement | null;
	small?: boolean;
};

export default class Instance {
	private instance: AxiosInstance;

	constructor(baseURL: string, config: LoaderConfig = { loader: false }) {
		this.instance = axios.create({
			baseURL,
		});

		config.loader = config.loader || false;

		if (config.loader) {
			this.addRequestInterceptor(
				(request: InternalAxiosRequestConfig) => {
					if (config.container) {
						config.container.innerHTML = `
                            <div class='flex flex-col items-center justify-center ${
															config.small ? 'mt-0 pt-5' : 'mt-6 pt-0'
														}'>
                                <div class='flex ${
																	config.small ? 'size-8' : 'size-10'
																} items-center justify-center rounded-full bg-primary-100'>
                                    <sl-spinner class='sl-spinner-primary ${
																			config.small
																				? 'sl-spinner-xs'
																				: 'sl-spinner-sm'
																		}' slot='prefix'></sl-spinner>
                                </div>`;

						if (config.title && config.description) {
							config.container.innerHTML += `<div class='text-center mt-3 ${
								config.small ? 'pb-5' : 'pb-0'
							}'>
                                <h3 class='${
																	config.small ? 'text-base' : 'text-lg'
																}' role='heading'>${config.title}</h3>
                                <p class='${
																	config.small ? 'text-sm' : 'text-base'
																}'>${config.description}</p>
                            </div>`;
						}

						config.container.innerHTML += '</div>';
					}

					return request;
				},
				(error: unknown) => Promise.reject(error),
			);

			this.addResponseInterceptor(
				(response: AxiosResponse) => {
					if (config.container) {
						config.container.innerHTML = '';
					}

					return response;
				},
				(error: unknown) => Promise.reject(error),
			);
		}
	}

	public addRequestInterceptor(
		onFulfilled?: (
			config: InternalAxiosRequestConfig,
		) => InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>,
		onRejected?: (error: any) => any,
	): number {
		return this.instance.interceptors.request.use(onFulfilled, onRejected);
	}

	public addResponseInterceptor(
		onFulfilled?: (
			response: AxiosResponse,
		) => AxiosResponse | Promise<AxiosResponse>,
		onRejected?: (error: any) => any,
	): number {
		return this.instance.interceptors.response.use(onFulfilled, onRejected);
	}

	public async Get<T>(
		url: string,
		config?: AxiosRequestConfig,
	): Promise<Promise<AxiosResponse<T>> | any> {
		return this.instance.get(url, config);
	}

	public async Post<T>(
		url: string,
		data?: any,
		config?: AxiosRequestConfig,
	): Promise<Promise<AxiosResponse<T>> | any> {
		return this.instance.post(url, data, config);
	}

	public async Put<T>(
		url: string,
		data?: any,
		config?: AxiosRequestConfig,
	): Promise<Promise<AxiosResponse<T>> | any> {
		return this.instance.put(url, data, config);
	}

	public async Patch<T>(
		url: string,
		data?: any,
		config?: AxiosRequestConfig,
	): Promise<Promise<AxiosResponse<T>> | any> {
		return this.instance.patch(url, data, config);
	}

	public async Delete<T>(
		url: string,
		config?: AxiosRequestConfig,
	): Promise<Promise<AxiosResponse<T>> | any> {
		return this.instance.delete(url, config);
	}
}
