import type {
	SlButton,
	SlDetails,
	SlDialog,
	SlDropdown,
	SlIcon,
	SlIconButton,
	SlInput,
	SlTabGroup,
} from '@shoelace-style/shoelace';

export const ref = <T extends HTMLElement>(
	selector: string,
	scope: ParentNode = document,
): T | null => scope.querySelector<T>(selector);

export const refs = <T extends HTMLElement>(
	selector: string,
	scope: ParentNode = document,
): NodeListOf<T> => scope.querySelectorAll(selector);

export const listener = (
	selector:
		| string
		| HTMLDivElement
		| HTMLOListElement
		| SlButton
		| SlDropdown
		| SlDialog
		| SlIcon
		| SlIconButton
		| SlInput
		| SlTabGroup
		| SlDetails,
	event: string,
	action: (event: Event | any) => void,
	bool?: boolean,
): void => {
	if (typeof selector === 'string') {
		const element: HTMLElement | null = ref(selector);

		element?.addEventListener(event, action, bool);
	} else {
		selector.addEventListener(event, action, bool);
	}
};
