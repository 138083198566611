export enum ETypeAuthentification {
	WeFix = 'WEFIX',
	WeFixPro = 'WEFIXPRO',
	Google = 'GOOGLE',
	Apple = 'APPLE',
	Facebook = 'FACEBOOK',
}

export enum ETypeEspaceClient {
	Client = 'CLIENT',
	Pro = 'PRO',
}
